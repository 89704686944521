import Vue from 'vue';
import Vuex from 'vuex';
import data_api from "@/store/api/data_api";

Vue.use(Vuex);


const state = {
    user: {},
    sidenav_open: false,
    payment_succeeded: false,
    flash_obj: {},
    subscription: {},
    securities: [],
    transactions: [],
    accounts: [],
    aggregate_data: {},
    profile_visibility: false,
    stripe_form_visable: false,
    purchase_type: '',
    appearance: {},
    stripe: null,
    monthly_intent: {},
    yearly_intent: {},
    elements_monthly: null,
    elements_yearly: null,
    monthly_intent_loading: false,
    yearly_intent_loading: false,
    subscriptions_loading: true,
    investments_loading: true,
    transactions_loading: true,
    aggregate_loading: true,
    accounts_loading: true
}

const actions = {
    login({ commit }, payload) {
        return new Promise((resolve, reject) => {
            data_api.create_fetch_request("POST", data_api.login_url, payload).then(resp => {

                console.log("RESPPP", resp)
                resolve(resp)
            })
        })
    },
    signup({ commit }, payload) {
        return new Promise((resolve, reject) => {
            data_api.create_fetch_request("POST", data_api.signup_url, payload).then(resp => {
                resolve(resp)
            })
        })
    },
    fetch_user_session({ commit }, vue_router) {
        return new Promise((resolve, reject) => {
            let auth_routes = ['/profile', '/dashboard']
            let user = undefined;
            data_api.create_fetch_request("GET", data_api.get_user_session_url).then(response => {
                // console.log("get session response", response)
                if (response && response.success) {
                    resolve(response.session);
                    user = response.session;
                    commit('set_user', user);
                    vue_router.push('/dashboard')

                } else if (response.error && response.error === "no_auth") {
                    let currentRoute = vue_router.currentRoute.path;
                    auth_routes.forEach(a_route => {
                        if (currentRoute.startsWith(a_route)) {
                            vue_router.push('/login')
                        }
                    })
                }
            }).finally(() => {
                resolve(user);
            })
        });
    },
    fetch_subscriptions({ commit }) {
        return new Promise((resolve, reject) => {
            data_api.create_fetch_request("POST", data_api.get_subscriptions_url).then(resp => {
                if (resp.success) {
                    commit('set_subscription', resp.subscription)
                    console.log(resp);

                }
                resolve(resp);
            }).finally(() => {
                commit('set_subscription_loading', false)
            })
        })
    },
    fetch_monthly_intent({ commit }) {
        commit('set_monthly_intent_loading', false)
        return new Promise((resolve, reject) => {
            data_api.create_fetch_request("POST", data_api.client_secret_url, { purchase_type: 'monthly' }).then(resp => {
                console.log(">>>>Monthly intent loading", resp)
                if (resp) {
                    let monthly_intent = { client_secret: resp.client_secret }
                    commit('set_monthly_intent', monthly_intent)
                    console.log("resp.success", monthly_intent)

                    resolve(monthly_intent)
                } else if (resp.error) {
                    resolve(resp)
                }
            }).catch(e => {
                resolve({ "error": "Unable to process request" })
            })
        })
    },
    fetch_yearly_intent({ commit }) {
        commit('set_yearly_intent_loading', false)
        return new Promise((resolve, reject) => {
            data_api.create_fetch_request("POST", data_api.client_secret_url, { purchase_type: 'yearly' }).then(resp => {
                if (resp) {
                    let yearly_intent = { client_secret: resp.client_secret }
                    commit('set_yearly_intent', yearly_intent)
                    resolve(yearly_intent);
                } else if (resp.error) {
                    resolve(resp.error)
                }
            }).catch(e => {
                resolve({ "error": "Unable to process request" })
            })
        });
    },
    fetch_investments({ commit }) {
        commit('set_investments_loading', true)
        return new Promise((resolve, reject) => {
            data_api.create_fetch_request("GET", data_api.get_investments_url).then(resp => {
                if (resp.success) {
                    console.log("securities", resp.securities)
                    commit('set_securities', resp.securities)
                } else if (resp.error) {
                    resolve(resp)
                }
            }).catch(e => {
                resolve({ "error": "Unable to process request" })
            }).finally(() => {
                commit('set_investments_loading', false)
            })
        });
    },
    fetch_transactions({ commit }) {
        commit('set_transactions_loading', true)
        return new Promise((resolve, reject) => {
            data_api.create_fetch_request("GET", data_api.get_transactions_url).then(resp => {
                if (resp.success) {
                    console.log("transactions", resp.transactions)
                    commit('set_transactions', resp.transactions)
                } else if (resp.error) {
                    resolve(resp)
                }
            }).catch(e => {
                resolve({ "error": "Unable to process request" })
            }).finally(() => {
                commit('set_transactions_loading', false)
            })
        })
    },
    fetch_accounts({ commit }) {
        commit('set_accounts_loading', true)

        return new Promise((resolve, reject) => {
            data_api.create_fetch_request("POST", data_api.get_accounts_url).then(resp => {
                if (resp.success) {
                    commit('set_accounts', resp.accounts)
                } else if (resp.error) {
                    resolve(resp)
                }
            }).catch(e => {
                resolve({ "error": "Unable to process request" })
            }).finally(() => {
                commit('set_accounts_loading', false)
            })
        })
    },
    fetch_floats({ commit }, aggregate) {
        let keys = Object.keys(aggregate);
        if (keys.length) {
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                (function (symbol) {
                    data_api.create_fetch_request("POST", data_api.get_float_url, { "symbol": symbol }).then(resp => {
                        commit('set_float', { k: symbol, float: resp.float })
                    })
                })(key)
            }
        }
    },
    get_plaid_link_token({ commit }, reauth = false) {
        return new Promise((resolve, reject) => {
            data_api.create_fetch_request("POST", data_api.get_plaid_link_token_url, { reauth: reauth }).then(resp => {
                if (resp.success) {
                    resolve(resp)
                } else {
                    resolve(resp)
                }
            }).catch(e => {
                resolve({ "error": "Unable to process request" })
            })
        })
    },
    reset_store({ commit }) {
        return new Promise((resolve, reject) => {
            commit('reset_store');
            resolve(true)
        });
    },
    fetch_aggregate({ commit }) {
        commit('set_aggregate_loading', true)
        data_api.create_fetch_request("GET", data_api.get_aggregate_url).then(resp => {
            if (resp.success) {
                commit('set_aggregate_data', resp.aggregate_data)
            }
        }).finally(() => {
            commit('set_aggregate_loading', false)
        })
    },

    update_profile_url: function ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            data_api.create_fetch_request("POST", data_api.save_profile_url, payload).then(resp => {
                console.log("SAVE_PROFILE_URL_RESPONSE", payload)
                resolve(resp)
            })
        });
    },
}

const mutations = {
    set_user: function (state, user) {
        state.user = user;
    },
    set_current_result: function (state, result) {
        state.current_result = result;
    },
    set_side_nav_open: function (state, side_nav_open) {
        state.sidenav_open = side_nav_open;
    },
    set_index_active_searches: function (state, active_searches) {
        state.index_active_searches = active_searches;
    },
    set_index_search_input: function (state, search_input) {
        state.index_search_input = search_input
    },
    set_flash_message: function (state, flash_obj) {
        state.flash_obj = flash_obj;
    },
    set_subscription: function (state, subscription) {
        state.subscription = subscription
    },
    set_securities: function (state, securities) {
        state.securities = securities
    },
    set_transactions: function (state, transactions) {
        state.transactions = transactions;
    },
    set_aggregate_data: function (state, data) {
        state.aggregate_data = data;
    },
    set_stripe_form_visable: function (state, visable) {
        state.stripe_form_visable = visable;
    },
    set_purchase_type: function (state, pt) {
        state.purchase_type = pt;
    },

    toggle_visibility: function (state, visibility) {
        state.user.visibility = visibility;
    },
    set_stripe: function (state, stripe) {
        state.stripe = stripe;
    },
    set_monthly_intent: function (state, intent) {
        console.log("intent***,", intent)
        state.monthly_intent = intent;
    },
    set_yearly_intent: function (state, intent) {
        state.yearly_intent = intent;
    },
    set_subscription_loading: function (state, loading) {
        state.subscriptions_loading = loading
    },
    set_monthly_intent_loading: function (state, loading) {
        state.monthly_intent_loading = loading
    },
    set_yearly_intent_loading: function (state, loading) {
        state.yearly_intent_loading = loading;
    },
    set_investments_loading: function (state, loading) {
        state.investments_loading = loading;
    },
    set_transactions_loading: function (state, loading) {
        state.transactions_loading = loading;
    },
    set_accounts: function (state, accounts) {
        state.accounts = accounts;
    },
    set_accounts_loading: function (state, loading) {
        state.accounts_loading = loading;
    },
    set_float: function (state, float_obj) {
        let symbol = float_obj.k;
        let float = float_obj.float;
        state.aggregate_data[symbol].float = float;
    },
    reset_store: function (state) {
        state.user = {};
        state.flash_obj = {};
        state.subscription = {};
        state.securities = [];
        state.transactions = [];
        state.aggregate_data = {};
        state.profile_visibility = false;
        state.purchase_type = '';
        state.appearance = {};
        state.stripe = null;
        state.monthly_intent = {};
        state.yearly_intent = {};
        state.elements_monthly = null;
        state.elements_yearly = null;
        state.monthly_intent_loading = false;
        state.yearly_intent_loading = false;
        state.subscriptions_loading = true;
        state.investments_loading = true;
        state.transactions_loading = true;
        state.aggregate_loading = true;
        state.accounts_loading = false;
    },
    set_aggregate_loading: function (state, loading) {
        state.aggregate_loading = loading;
    },
    set_elements_monthly: function (state, elements_monthly) {
        state.elements_monthly = elements_monthly
    },
    set_elements_yearly: function (state, elements_yearly) {
        state.elements_yearly = elements_yearly
    },
    reset_intents_loading: function (state) {
        state.yearly_intent_loading = false;
        state.monthly_intent_loading = false;
    },
    update_float: function (state, payload) {
        let symbol = payload.symbol;
        let float = payload.float;
    },
    create_stripe_form: function (state, payload) {
        state.stripe = payload.stripe;
        // console.log("index stripe", payload)
        state.monthly_intent = payload.monthly_intent;
        // console.log("AASDASDPEE>>", payload)
        state.purchase_type = payload.purchase_type;
        // console.log("PURCHASE>>>>>>>>>", purchase_type)
        state.appearance = payload.appearance






    }
}

const getters = {
    get_user: state => state.user,
    get_sidenav_open: state => state.sidenav_open,
    get_flash_obj: state => state.flash_obj,
    get_subscription: state => state.subscription,
    get_securities: state => state.securities,
    get_transactions: state => state.transactions,
    get_aggregate_data: state => state.aggregate_data,
    get_aggregate_loading: state => state.aggregate_loading,
    get_stripe_form_visable: state => state.stripe_form_visable,
    get_purchase_type: state => state.purchase_type,
    get_monthly_intent: state => state.monthly_intent,
    get_yearly_intent: state => state.yearly_intent,
    get_subscriptions_loading: state => state.subscriptions_loading,
    get_stripe: state => state.stripe,
    get_monthly_intent_loading: state => state.monthly_intent_loading,
    get_yearly_intent_loading: state => state.yearly_intent_loading,
    get_investments_loading: state => state.investments_loading,
    get_transactions_loading: state => state.transactions_loading,
    get_elements_monthly: state => state.elements_monthly,
    get_elements_yearly: state => state.elements_yearly,
    get_accounts: state => state.accounts,
    get_accounts_loading: state => state.accounts_loading
}

export default new Vuex.Store({
    getters,
    actions,
    mutations,
    state
})