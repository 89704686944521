<template>
    <div class="dashboard-child-view dashboard-manage-accounts" :class="{'flash': flash_obj_level, 'connected': accounts}">
        <div v-if="subscriptions_loading || accounts_loading" class="page-loading">
            <font-awesome-icon class="fa-spin" :icon="['fa', 'sun']"></font-awesome-icon>
        </div>
        <div v-if="flash_obj_level" :class="flash_obj_level" class="flash">
            {{ flash_obj_message }}
            <font-awesome-icon @click="remove_flash" :icon="['fa', 'window-close']"></font-awesome-icon>
        </div>
        <div class="dashboard-title">
            Manage Accounts
            <font-awesome-icon v-if="subscription && accounts.length" @click="launch_plaid_modal" :icon="['fa', 'edit']"></font-awesome-icon>
        </div>
        <div v-if="subscription" class="link-accounts" :class="{'connected': accounts.length}">
            <div v-if="accounts.length" class="accounts-title"></div>
            <div v-if="accounts.length" class="accounts">
                <div v-for="account in accounts" class="account">
                    <span class="account-name">{{ account.name }}</span>
                    <span class="account-mask">{{ account.mask }}</span>
                </div>
            </div>
            <div v-else>
                <div class="dashboard-subtitle">
                    Your investment accounts are not currently connected. Click the button below to connect to your investment account with Plaid.
                </div>
                <div class="plaid-logo-parent">
                    <div class="plaid-logo-inner">
                        <span>powered by</span>
                        <img :src="plaid_logo" alt="">
                    </div>
                </div>
                <div class="plaid-signin-parent">
                    <button class="plaid-signin" @click="start_plaid_auth">Connect your account</button>
                </div>
            </div>
        </div>
        <div class="dashboard-subtitle" v-else>
            You must have a subscription before you can link your accounts. You can
            <span class="redirect-link" @click="$emit('goto', '/subscriptions')">purchase one here</span>
        </div>
        <div class="plaid-modal" :class="{'visable': plaid_modal}">
            <div class="stripe-modal-backdrop"></div>
            <div class="close-modal">
                <font-awesome-icon :icon="['fa', 'window-close']" @click="close_plaid_modal"></font-awesome-icon>
            </div>
            <!-- define the stripe monthly form-->
            <div class="plaid-modal-content" :class="{'slide-up': plaid_modal, 'slide-down': !plaid_modal}">
                <img class="modal-logo" :src="site_logo" alt="">
                <div class="form-title">Please re-authenticate with plaid in order to Link/Unlink your accounts</div>
                <div class="plaid-signin-parent">
                    <button v-if="plaid_reauth" class="plaid-signin">
                        <font-awesome-icon class="fa-spin" :icon="['fa', 'spinner']"></font-awesome-icon>
                    </button>
                    <button v-else class="plaid-signin" @click="start_plaid_auth(true)">Connect your account</button>
                </div>
            </div>
            <!-- end stripe yearly -->
        </div>
    </div>
</template>
<script>
import Dashboard from "@/views/Dashboard.vue";
import data_api from "@/store/api/data_api";
import plaid_logo from "@/assets/img/plaid-logo.svg";
import site_logo from "@/assets/img/retail-float-logo.png";

export default {
    name: "DashboardManageAccounts",
    mounted() {
        this.$store.dispatch('fetch_subscriptions')
        let accounts = this.$store.getters.get_accounts;
        if (!accounts.length) {
            this.$store.dispatch('fetch_accounts')
            this.$store.dispatch('get_plaid_link_token').then(resp => {
                if (resp.success) {
                    this.plaid_link_token = resp.link_token;
                }
            })
        }
    },
    data() {
        return {
            plaid_link_token: '',
            securities: [],
            securities_columns: [],
            plaid_logo: plaid_logo,
            plaid_modal: false,
            site_logo: site_logo,
            plaid_reauth: false
        }
    },
    components: {Dashboard},
    methods: {
        close_plaid_modal: function() {
            this.plaid_modal = false;
        },
        launch_plaid_modal: function () {
            this.$store.commit('set_side_nav_open', false)
            this.plaid_modal = true;
        },

        plaid_on_success: async function(public_token, metadata) {
            data_api.create_fetch_request("POST", data_api.exchange_access_token_url, {public_token: public_token}).then(resp => {
                if (resp.success) {
                    this.$store.commit('set_flash_message', {'level': 'success', 'message': 'Your plaid accounts are now connected!'})
                    this.$store.dispatch('fetch_investments');
                    this.$store.dispatch('fetch_accounts');
                }
            })
        },
        plaid_on_exit: (err, metadata) => {
            localStorage.setItem('link_token', undefined)
        },
        plaid_on_event: (eventName, metadata) => {
            console.log(`Event ${eventName}`);
        },
        open_plaid_auth: function(link_token) {
            const handler = Plaid.create({
                token: link_token || this.plaid_link_token,
                onSuccess: this.plaid_on_success,
                onExit: this.plaid_on_exit,
                onEvent: this.plaid_on_event
            })
            handler.open();
        },
        start_plaid_auth: function(reauthenticate=false) {
            let self = this;
            this.plaid_modal = false;
            if (reauthenticate) {
                this.plaid_reauth = true;
                this.$store.dispatch('get_plaid_link_token', true).then(resp => {
                    if (resp.success) {
                        console.log(resp.link_token)
                        this.open_plaid_auth(resp.link_token);
                    } else {
                        console.log("problem with reauth", resp);
                    }
                }).finally(() => {
                    this.plaid_reauth = false;
                })
                console.log("user is trying to re-athenticate with plaid")
            } else {
                if (this.plaid_link_token) {
                    this.open_plaid_auth()
                }
            }
        },
        remove_flash: function() {
            this.$store.commit('set_flash_message', {})
        }
    },
    computed: {
        subscriptions_loading: function() {
            return this.$store.getters.get_subscriptions_loading;
        },
        accounts_loading: function() {
            return this.$store.getters.get_accounts_loading;
        },
        accounts: function() {
            return this.$store.getters.get_accounts;
        },
        subscription: function() {
            let subscription = this.$store.getters.get_subscription;
            if (subscription.status && (subscription.status === 'active' || subscription.status === 'canceled')) {
                return subscription
            } else {
                return false
            }
        },
        flash_obj_message: function() {
            let flash_obj = this.$store.getters.get_flash_obj;
            if (flash_obj.level) {
                return flash_obj.message
            }
            return '';
        },
        flash_obj_level: function() {
            let flash_obj = this.$store.getters.get_flash_obj;
            if (flash_obj.level) {
                return flash_obj.level
            }
            return '';
        }
    }
}
</script>