<template>
    <div class="dashboard-child-view checkout">
        <div class="form-error" v-if="form_error">{{ form_error }}</div>
        <div v-if="loading" class="page-loading">
            <font-awesome-icon class="fa-spin" :icon="['fa', 'sun']"></font-awesome-icon>
        </div>
        <div style="display: none">{{ stripe }}</div>
    </div>
</template>

<script>
import data_api from "@/store/api/data_api";

export default {
    name: "DashboardCheckout",
    data() {
        return {
            loading: true,
            form_error: ''
        }
    },
    mounted() {
    },
    computed: {
        stripe: function() {
            let stripe = this.$store.getters.get_stripe;
            if (stripe) {
                this.check_status(stripe);
            }
        }
    },
    methods: {
        check_status: function(stripe) {
            const client_secret = new URLSearchParams(window.location.search).get(
                "payment_intent_client_secret"
            );
            if (!client_secret) {
                return;
            }
            stripe.retrievePaymentIntent(client_secret).then(payment_intent => {
                const paymentMethodId = payment_intent.paymentIntent.payment_method;
                switch (payment_intent.paymentIntent.status) {
                    case "processing":
                        this.form_error = "Your payment is processing.";
                        this.getSubscriptions();
                        break;
                    case "requires_payment_method":
                        this.form_error = "Your payment was not successful, please try again.";
                        break;
                    default:
                        this.form_error = "Something went wrong.";
                        break;
                    case "succeeded":
                        console.log("Call create_subscription_intent endpoint")
                        const amount = payment_intent.paymentIntent.amount
                        let purchaseType
                        if (amount == 500) {
                            purchaseType = "monthly"
                        } else if (amount == 3000) {
                            purchaseType = "yearly"
                        }
                        console.log("purchaseType", purchaseType);
                        data_api.create_fetch_request("POST", data_api.create_subscription_intent_url, { "purchaseType": purchaseType, "paymentMethod": paymentMethodId }).then(resp => {
                            console.log(resp);
                            if (resp.success && resp.client_secret) {
                                // Continue with Stripe setup using the new client_secret
                                // this.setupStripe(resp.client_secret);
                                this.getSubscriptions();
                            } else {
                                console.log("Error:", resp.error);
                                this.form_error = "Error creating subscription intent.";
                            }
                        }).finally(() => {
                            this.loading = false;
                        });
                break;

                }
                
            });
            
        },
        getSubscriptions: function () {
            data_api.create_fetch_request("POST", data_api.get_subscriptions_url)
                .then(resp => {
                    if (resp.success && resp.subscription) {
                        this.$store.commit('set_subscription', resp.subscription);
                        this.$store.commit('set_flash_message', { level: 'success', message: "Thank you for your purchase!" });
                        this.$emit('goto', "/manage_accounts");
                    } else {
                        console.log("response", resp);
                    }
                })
                .catch(error => {
                    console.error("Error fetching subscriptions:", error);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
}
</script>

