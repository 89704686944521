<template>
    <div class="login auth view">
        <div class="auth-form">
            <div class="title-text">Login</div>
            <div v-if="form_error" class="form-error">{{ form_error }}</div>
            <div class="input-container">
                <input
                    type="text"
                    id="email"
                    name="email"
                    aria-labelledby="label-email"
                    v-model="email"
                    :class="{active: email_active}"
                    @input="email_onenter"
                />
                <label class="label" for="email" id="label-email">
                    <div class="text">Email</div>
                </label>
            </div>
            <div class="input-container">
                <input
                    type="password"
                    id="password"
                    name="password"
                    aria-labelledby="label-password"
                    v-model="password"
                    :class="{active: password_active}"
                    @input="password_onenter"
                />
                <label class="label" for="password" id="label-password">
                    <div class="text">Password</div>
                </label>
            </div>
            <button v-if="auth_in_action" class="login-btn action-btn disabled" :disabled="auth_in_action">
                <font-awesome-icon class="fa-spin" :icon="['fa', 'spinner']"></font-awesome-icon>
            </button>
            <button v-else @click="login" class="login-btn action-btn">Login</button>
            <button id="google-signin">Sign in with google</button>
            <div class="text-redirect" @click="$router.push('/signup')">No account? Go to signup</div>
        </div>
        <div style="display: none">{{ get_user }}</div>
    </div>
</template>

<script>

// import { useAuth0 } from '@auth0/auth0-vue';
import data_api from "@/store/api/data_api";

export default {
    name: "Login",
    mounted() {
        let self = this;

        document.body.onkeyup = function(e) {
            if (e.key === 'Enter') {
                self.login();
            }
        }

        google.accounts.id.initialize({
            client_id: '370583349345-fth5sf5e5hb23qqcok7buaruk1qe92i2.apps.googleusercontent.com',
            callback: self.onsuccess
        });

        google.accounts.id.renderButton(document.getElementById('google-signin'), {
            size: "medium",
            type: "standard"
        });
        // google.accounts.id.prompt();
    },
    data() {
        return {
            email: '',
            password: '',
            email_active: false,
            password_active: false,
            form_error: '',
            auth_in_action: false,
            client: null
        }
    },
    computed: {
        get_user: function() {
            let user = this.$store.getters.get_user;
            if (user.email) {
                console.log("user in login", user.email)
                this.$router.push('/dashboard')
            }
        }
    },
    methods: {
        parse_jwt: function(token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            return JSON.parse(jsonPayload);
        },
        onsuccess: function(result) {
            console.log("result", result)
            let parsed = this.parse_jwt(result.credential)
            console.log(parsed);
            if (parsed && parsed.email && parsed.name) {
                if (parsed.name.indexOf(" ") > -1) {
                    parsed.name = parsed.name.split(" ")[0]
                }
                let google_user = {
                    "username": parsed.name,
                    "email": parsed.email,
                    "login_type": "google",
                    "token": result
                }
                this.auth_in_action = true;

                data_api.create_fetch_request("POST", data_api.login_url, google_user).then(resp => {
                    if (resp && resp.success) {
                        this.$store.commit('set_user', resp.session)
                    } else if (resp && resp.error) {
                        if (resp.error === "is_auth") {
                            // this.$store.dispatch('fetch_user_session', this.$router);
                            window.location.reload()
                        } else {
                            this.form_error = resp.error;
                        }
                    }
                }).finally(() => {
                    this.auth_in_action = false;
                })
            }
        },
        email_onenter: function(e) {
            if (e.target.value.trim().length > 0) {
                this.email_active = true;
            } else {
                this.email_active = false
            }
        },
        password_onenter: function(e) {
            if (e.target.value.trim().length > 0) {
                this.password_active = true;
            } else {
                this.password_active = false
            }
        },
        login: function() {
            let self = this;
            let [email, password] = [this.email.trim(), this.password.trim()]
            if (email && password) {
                this.auth_in_action = true;
                let login_payload = {email: email, password: password}
                this.$store.dispatch('login', login_payload).then(resp => {
                    self.auth_in_action = false;
                    if (resp && resp.error) {
                        this.form_error = resp.error;
                    } else if (resp.success) {
                        this.$store.commit('set_user', resp.session)
                        this.$router.push('/dashboard')
                    }
                })
            } else {
                this.form_error = "Email and password are required"
            }
        },
    }
}
</script>
