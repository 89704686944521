<template>
    <div class="dashboard-child-view profile">
        <div class="auth-form" v-if="login_type !== 'google' ">
            <div class="dashboard-title">Edit Profile</div>
            <div v-if="form_error" class="form-error">{{ form_error }}</div>
            <div class="input-container">
                <input
                    type="text"
                    id="email"
                    name="email"
                    aria-labelledby="label-email"
                    v-model="email"
                    :class="{active: email_active}"
                    @input="email_onenter"
                />
                <label class="label" for="email" id="label-email">
                    <div class="text">Email</div>
                </label>
            </div>
            <div class="input-container">
                <input
                    type="text"
                    id="username"
                    name="username"
                    aria-labelledby="label-username"
                    v-model="username"
                    :class="{active: username_active}"
                    @input="username_onenter"
                />
                <label class="label" for="email" id="label-username">
                    <div class="text">Username</div>
                </label>
            </div>
            <div class="input-container">
                <input
                    type="password"
                    id="password"
                    name="password"
                    aria-labelledby="label-password"
                    v-model="password"
                    :class="{active: password_active}"
                    @input="password_onenter"
                />
                <label class="label" for="password" id="label-password">
                    <div class="text">Password</div>
                </label>
            </div>
            <button v-if="form_loading" class="login-btn action-btn disabled" :disabled="form_loading">
                <font-awesome-icon class="fa-spin" :icon="['fa', 'spinner']"></font-awesome-icon>
            </button>
            <button v-else @click="save" class="login-btn action-btn">Save</button>
        </div>
        <div class="auth-form" v-if="login_type === 'google'">
            Your account information is managed by google
        </div>
        <div style="display:none;">{{current_email}}</div>
        <div style="display:none;">{{current_username}}</div>
    </div>
</template>

<script>
import data_api from "@/store/api/data_api";

export default {
    name: "DashboardProfile",
    data() {
        return {
            email: '',
            username: '',
            password: '',
            email_active: false,
            username_active: false,
            password_active: false,
            form_loading: false,
            form_error: false
        }
    },
    computed: {
        login_type: function() {
            let user = this.$store.getters.get_user;
            return user.login_type || '';
        },
        current_email: function() {
            let user = this.$store.getters.get_user;
            if (user) {
                this.email = user.email;
                this.email_active = true
            }
        },
        current_username: function() {
            let user = this.$store.getters.get_user;
            if (user) {
                this.username = user.username;
                this.username_active = true
            }
        }
    },
    methods: {
        email_onenter: function(e) {
            if (e.target.value.trim().length > 0) {
                this.email_active = true;
            } else {
                this.email_active = false
            }
        },
        password_onenter: function(e) {
            if (e.target.value.trim().length > 0) {
                this.password_active = true;
            } else {
                this.password_active = false
            }
        },
        username_onenter: function(e) {
            if (e.target.value.trim().length > 0) {
                this.username_active = true;
            } else {
                this.username_active = false
            }
        },
        save: function() {
            let self = this;
            let [email, username, password] = [this.email.trim(), this.username.trim(), this.password.trim()];
            let payload = {};
            let existing_user = this.$store.getters.get_user;

            if (email && email !== existing_user.email) {
                payload.email = email;
                existing_user.email = email
            }
            if (username && username !== existing_user.username) {
                payload.username = username;
                existing_user.username = username;
            }
            if (password) {
                payload.password = password
            }
            this.form_loading = true;



             this.$store.dispatch('update_profile_url', payload).then(resp => {
                if (resp.success) {

                    this.$store.commit('set_user', existing_user);

                } else if (resp.error) {
                    this.form_error = resp.error;
                console.log("SAVE PAYLOAD RESPONSE", resp.error)

                }
            }).finally(() => {
                self.form_loading = false;
            }).catch(error => {
                console.error('Error:', error);
                self.form_error = 'An error occurred while saving the profile.';
                self.form_loading = false;
            });
            // if (email && password) {
            //     this.auth_in_action = true;
            //     let login_payload = {email: email, password: password}
            //     this.$store.dispatch('login', login_payload).then(resp => {
            //         self.auth_in_action = false;
            //         if (resp && resp.error) {
            //             this.form_error = resp.error;
            //         } else if (resp.success) {
            //             console.log("logging in");
            //             this.$store.commit('set_user', resp.session)
            //             this.$router.push('/dashboard')
            //         }
            //     })
            // } else {
            //     this.form_error = "Email and password are required"
            // }
        }
    }
}
</script>
