<template>
    <div class="index view">
        <img :src="index_logo" alt="" class="index-logo img-responsive">
    </div>
</template>

<script>
import Header from "@/components/Header.vue";
import site_logo from '@/assets/img/retail-float-logo.png';

export default {
    mounted() {},
    components: {Header},
    methods: {},
    computed: {},
    data() {
        return {
            index_logo: site_logo
        }
    }
}
</script>
