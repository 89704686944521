<template>
    <div id="app">
        <Header></Header>
        <div class="wrapper">
            <router-view></router-view>
        </div>
<!--        <Footer></Footer>-->
    </div>
</template>

<script>

import Header from "@/components/Header.vue";

export default {
    name: 'App',
    components: {
        Header,
    },
    computed: {

    },
    mounted() {
        this.$store.commit('set_stripe', Stripe(process.env.VUE_APP_STRIPE_TEST_KEY))
        this.$store.dispatch('fetch_user_session', this.$router).then(user => {
if (user) {
                // only bother fetching data if the user is logged in
                this.$store.dispatch('fetch_subscriptions');
                this.$store.dispatch('fetch_accounts')
                this.$store.dispatch('fetch_aggregate')
            }
        })

        // this.$store.dispatch('fetch_investments');
        // this.$store.dispatch('fetch_transactions');
        // this.$store.dispatch('fetch_aggregate');
        // this.$store.dispatch("create_subscription_intent", "monthly");
        // this.$store.dispatch("create_subscription_intent", "yearly");
    

    }


}
</script>

