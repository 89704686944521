class DataApi {
    constructor() {
        // Set base URL explicitly or configure it through environment variables
        this.base_url = 'https://server-z3f4bppy3a-uc.a.run.app'; // Example: Change this to your actual base URL

        // API endpoints
        this.login_url = `${this.base_url}/api/auth/login`;
        this.logout_url = `${this.base_url}/api/auth/logout`;
        this.signup_url = `${this.base_url}/api/auth/signup`;
        this.get_user_session_url = `${this.base_url}/api/auth/session`;
        this.forgot_password_url = `${this.base_url}/api/user/forgot`;
        this.password_reset_url = `${this.base_url}/api/user/password_reset_submit`;
        this.check_password_reset_credentials_url = `${this.base_url}/api/user/check_password_reset_credentials`;
        this.submit_review_url = `${this.base_url}/api/user/review_submit`;
        this.popular_searches_url = `${this.base_url}/api/user/popular_searches`;
        this.submit_claim_listing_url = `${this.base_url}/api/user/submit_claim_listing`;
        this.client_secret_url = `${this.base_url}/api/user/create_payment_intent`
        this.create_subscription_intent_url = `${this.base_url}/api/user/create_subscription_intent`
        this.save_profile_url = `${this.base_url}/api/user/save_profile`;
        this.set_subscription_active_url = `${this.base_url}/api/user/set_active_subscription`;
        this.get_subscriptions_url = `${this.base_url}/api/user/get_subscriptions`;
        this.cancel_subscription_url = `${this.base_url}/api/user/cancel_subscription`;
        this.get_plaid_link_token_url = `${this.base_url}/api/user/get_link_token`;
        this.exchange_access_token_url = `${this.base_url}/api/user/exchange_access_token`;
        this.get_investments_url = `${this.base_url}/api/user/get_investments`;
        this.get_transactions_url = `${this.base_url}/api/user/get_transactions`;
        this.get_aggregate_url = `${this.base_url}/api/user/get_aggregate`;
        this.toggle_visibility_url = `${this.base_url}/api/user/toggle_visibility`;
        this.get_public_profile_url = `${this.base_url}/api/user/get_public_profile`;
        this.get_float_url = `${this.base_url}/api/user/get_float`;
        this.get_accounts_url = `${this.base_url}/api/user/get_accounts`;
    }

    async create_fetch_request(method, api_url, body = {}) {
        let request_obj = {
            credentials: 'include',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json'
            },
            method: method
        };

        // Add body for methods that support it (not for GET or HEAD requests)
        if (method !== 'GET' && method !== 'HEAD') {
            // console.log('Body before stringify:', body);
            // Include additional parameters in the body
            if (Object.keys(body).length > 0) {
                request_obj.body = JSON.stringify(body);
                // console.log('Body after stringify:', request_obj.body);
            }
        }

        try {
            // Send the request and await the response
            const response = await fetch(api_url, request_obj);
            // console.log("<<Response Data>>", response)


            // Check if response is ok (status code 200-299)
            if (!response.ok) {
                throw new Error('Request failed with status ' + response.status);
            }

            // Parse the response body as JSON and return it
            return await response.json();

        } catch (error) {
            // Catch and handle any errors that occur during the request
            console.log('Fetch request error:', error);
            return { error: "Unable to process request" };
        }


        // Base URL should be set explicitly or configured through environment variables
        // get_base_url() {
        //     return `${document.location.origin}`;
        // }


    }


    get_checkout_url() {
        // return `http://localhost:8080/checkout`;
        return `https://retailfloat.com/checkout`;


    }
}

export default new DataApi();
