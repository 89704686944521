<template>
    <div class="dashboard-child-view subscriptions" :class="{'loading': loading}">
        <!-- if subscription, show active subscription table -->
        <div v-if="subscription" class="subscriptions-content">
            <span class="dashboard-title">
                View Subscriptions
            </span>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Quantity</th>
                        <th v-if="subscription && subscription.canceled_at">Date Canceled</th>
                        <th v-if="subscription && subscription.canceled_at">Active Until</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <td style="text-align: center">
                        <img :src="logo" alt="">
                    </td>
                    <td>
                        <div class="subscription-title">Retail Float Membership</div>
                        <div class="subscription-price">${{ (subscription.plan.amount/100).toFixed(2) }} USD / {{ subscription.plan.interval }}ly</div>
                    </td>
                    <td class="quantity-col">
                        1
                    </td>
                    <td v-if="subscription && subscription.canceled_at"> {{ get_date(subscription.canceled_at) }} </td>
                    <td v-if="subscription && subscription.canceled_at"> {{ get_date(subscription.current_period_end) }} </td>
                    <td>
                        <button v-if="cancel_btn_loading" class="cancel-btn" :disabled="cancel_btn_loading">
                            <font-awesome-icon class="fa-spin" :icon="['fa', 'fa-spinner']"></font-awesome-icon>
                        </button>
                        <button v-else-if="subscription && !subscription.canceled_at" class="cancel-btn" @click="cancel_subscription">Cancel</button>
<!--                        <button v-else-if="subscription && subscription.canceled_at" class="renew-btn">Renew</button>-->
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <!-- else if no subscription and we are still waiting on server -->
        <div v-else-if="subscriptions_loading" class="subscriptions-content">
            <div class="page-loading">
                <font-awesome-icon class="fa-spin" :icon="['fa', 'sun']"></font-awesome-icon>
            </div>
        </div>

        <!-- else if we are done loading then display the possible payment options -->
        <div v-else-if="!subscriptions_loading" class="subscriptions-content">
            <div class="dashboard-title">
                Subscriptions
            </div>
            <div class="dashboard-subtitle">
                Select a payment plan below
            </div>
            <table class="subscription-options">
                <thead>
                    <tr>
                        <th></th>
                        <th>name</th>
                        <th>quantity</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <img :src="logo" alt="">
                        </td>
                        <td>
                            <div class="subscription-title">Retail Float Membership</div>
                            <div class="subscription-price">$5.00 USD / month</div>
                        </td>
                        <td>1</td>
                        <td v-if="get_monthly_intent_loading">
                            <button class="subscription-purchase loading">
                                <font-awesome-icon class="fa-spin" :icon="['fa', 'spinner']"></font-awesome-icon>
                            </button>
                        </td>
                        <td v-else>
                            <button @click="load_purchase('monthly')" class="subscription-purchase">Purchase</button>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <img :src="logo" alt="">
                        </td>
                        <td>
                            <div class="subscription-title">Retail Float Membership</div>
                            <div class="subscription-price">$30.00 USD / year</div>
                        </td>
                        <td>1</td>
                        <td v-if="get_yearly_intent_loading">
                            <button class="subscription-purchase loading">
                                <font-awesome-icon class="fa-spin" :icon="['fa', 'spinner']"></font-awesome-icon>
                            </button>
                        </td>
                        <td v-else><button @click="load_purchase('yearly')" class="subscription-purchase">Purchase</button></td>
                    </tr>
                </tbody>
            </table>

            <div class="stripe-payment-form" :class="{'visable': stripe_form_visable}">
                <div class="stripe-modal-backdrop"></div>
                <div class="close-modal">
                    <font-awesome-icon :icon="['fa', 'window-close']" @click="close_stripe_modal"></font-awesome-icon>
                </div>
                <!-- define the stripe monthly form-->
                <div class="stripe-monthly" :class="{'form-visable': purchase_type === 'Monthly'}">
                    <img class="modal-logo" :src="logo" alt="">
                    <div class="form-title">Retail Float {{ purchase_type }} Plan</div>
                    <form id="payment-form" class="stripe-form" @submit="stripe_submit">
                        <div id="link-authentication-element-monthly">
                            <!--Stripe.js injects the Link Authentication Element-->
                        </div>
                        <div id="payment-element-monthly">
                            <!--Stripe.js injects the Payment Element-->
                        </div>
                        <button id="submit">
                            <div class="spinner hidden" id="spinner"></div>
                            <span id="button-text">Pay now</span>
                        </button>
                        <div v-if="payment_text" id="payment-message-monthly">{{ payment_text }}</div>
                    </form>
                </div>
                <!-- end stripe monthly -->
                <!-- define stripe yearly form -->
                <div class="stripe-yearly" :class="{'form-visable': purchase_type === 'Yearly'}">
                    <img class="modal-logo" :src="logo" alt="">
                    <div class="form-title">Retail Float {{ purchase_type }} Plan</div>
                    <form id="payment-form" class="stripe-form" @submit="stripe_submit">
                        <div id="link-authentication-element-yearly">
                            <!--Stripe.js injects the Link Authentication Element-->
                        </div>
                        <div id="payment-element-yearly">
                            <!--Stripe.js injects the Payment Element-->
                        </div>
                        <button id="submit">
                            <div class="spinner hidden" id="spinner"></div>
                            <span id="button-text">Pay now</span>
                        </button>
                        <div v-if="payment_text" id="payment-message-yearly"></div>
                    </form>
                </div>
                <!-- end stripe yearly -->
            </div>
        </div>
    </div>
</template>

<script>

import data_api from "@/store/api/data_api";
import site_logo from '@/assets/img/retail-float-logo.png';
import logo from "@/assets/img/retail-float-logo.png";

var stripe;
var appearance;

export default {


    name: "DashboardSubscriptions",
    components: {},
    data() {
        return {
            logo: site_logo,
            cancel_btn_loading: false,
            billing_email: '',
            loading: false,
            payment_text: ''
        }
    },

    mounted() {
        console.log("subscription: ", this.subscription)
        this.$store.commit('set_stripe', Stripe(process.env.VUE_APP_STRIPE_TEST_KEY))
        console.log(">>>>>>>>",this.$store.getters.get_stripe )
        console.log("[+] dashboard subscriptions mounted")
        // this.$store.dispatch('fetch_subscriptions').then(resp => {
        //     // subscription is not active so fetch the monthly and yearly intent
        //     if (resp && resp.error && resp.error === "subscription_not_active" || resp && resp.error && resp.error === "Unable to process request") {
        //          stripe = this.$store.getters.get_stripe;

        //         // set the appearance of the stripe form
        //         appearance = {
        //             theme: 'night',
        //             variables: {}
        //         };

        //             this.$store.dispatch('fetch_monthly_intent').then(monthly_intent => {
        //                 if (monthly_intent) {
        //                     this.create_stripe_form(stripe, appearance, monthly_intent, 'monthly')
        //                 }
        //             }).catch(e => {
        //                 // handle exception if request fails
        //             })

        //             this.$store.dispatch('fetch_yearly_intent').then(yearly_intent => {
        //                 if (yearly_intent.client_secret) {
        //                     this.create_stripe_form(stripe, appearance, yearly_intent, 'yearly')
        //                 }
        //             }).catch(e => {
        //                 // handle exception if request fails
        //             })
        //         // }
        //     }
        // })
    },
    computed: {
        subscriptions_loading: function() {
            return this.$store.getters.get_subscriptions_loading
        },
        get_monthly_intent_loading: function() {
            return this.$store.getters.get_monthly_intent_loading;
        },
        get_yearly_intent_loading: function() {
            return this.$store.getters.get_yearly_intent_loading;
        },
        stripe_form_visable: function() {
            return this.$store.getters.get_stripe_form_visable;
        },
        purchase_type: function() {
            let pt = this.$store.getters.get_purchase_type;
            if (pt.length) {
                return `${pt[0].toUpperCase()}${pt.slice(1, pt.length)}`;
            } else {
                return pt
            }
        },
        subscription: function() {
            let subscription = this.$store.getters.get_subscription;
            if (subscription.status && (subscription.status === 'active' || subscription.status === 'canceled')) {
                return subscription
            } else {
                return false
            }
        },
    },
    methods: {
        create_stripe_form: function(stripe, appearance, intent, purchase_type) {
        console.log("STRIPE FORM>>>>", stripe, appearance, intent.client_secret, purchase_type)
            let args = {appearance: appearance, clientSecret: intent.client_secret}
            let elements = stripe.elements(args);
            console.log("elementss.,.,><><> ", elements)
            const linkAuthenticationElement = elements.create("linkAuthentication");
            let element = document.getElementById('link-authentication-element-monthly');
            linkAuthenticationElement.mount(`#link-authentication-element-${purchase_type}`);
            linkAuthenticationElement.on('change', (event) => {
                this.billing_email = event.value.email;
            });
            const paymentElementOptions = {
                layout: "tabs",
            };
            const paymentElement = elements.create("payment", paymentElementOptions);
            paymentElement.mount(`#payment-element-${purchase_type}`);
            let commit_key_elements = `set_elements_${purchase_type}`
            let commit_key_intent_loading = `set_${purchase_type}_intent_loading`
            this.$store.commit(commit_key_elements, elements)
            this.$store.commit(commit_key_intent_loading, false)
        },

        load_purchase: function(purchase_type) {
            this.$store.commit('set_side_nav_open', false)
            this.$store.commit('set_purchase_type', purchase_type)
            this.$store.commit('set_stripe_form_visable', true)   

 if (purchase_type === 'monthly') {
                this.$store.dispatch('fetch_monthly_intent').then(monthly_intent => {
                    if (monthly_intent) {
                        this.create_stripe_form(this.$store.getters.get_stripe, { theme: 'night', variables: {} }, monthly_intent, 'monthly');
                    }
                }).catch(e => {
                    // Handle exception if request fails
                });
            } else if (purchase_type === 'yearly') {
                this.$store.dispatch('fetch_yearly_intent').then(yearly_intent => {
                    if (yearly_intent.client_secret) {
                        this.create_stripe_form(this.$store.getters.get_stripe, { theme: 'night', variables: {} }, yearly_intent, 'yearly');
                    }
                }).catch(e => {
                    // Handle exception if request fails
                });
            }
        },
        
        get_date: function(timestamp) {
            return new Date(timestamp * 1000).toLocaleString();
        },
        cancel_subscription: function() {
            this.cancel_btn_loading = true;
            data_api.create_fetch_request("POST", data_api.cancel_subscription_url).then(resp => {
                console.log("CANCEL SUB LOG>>>>>", resp)
                if (resp.success) {
                    console.log("subscription canceled", resp.subscription_canceled)
                    this.$store.commit('set_subscription', resp.subscription_canceled)
                    window.location.reload();
                }
            }).finally(() => {
                this.cancel_btn_loading = false;
            })
        },
        close_stripe_modal: function() {
            this.$store.commit('set_purchase_type', '')
            this.$store.commit('set_stripe_form_visable', false)
            this.payment_text = '';
        },
        setLoading: function(isLoading) {
            if (isLoading) {
                // Disable the button and show a spinner
                document.querySelector("#submit").disabled = true;
                document.querySelector("#spinner").classList.remove("hidden");
                document.querySelector("#button-text").classList.add("hidden");
            } else {
                document.querySelector("#submit").disabled = false;
                document.querySelector("#spinner").classList.add("hidden");
                document.querySelector("#button-text").classList.remove("hidden");
            }
        },
        stripe_submit: async function(e) {
            e.preventDefault();
            let current_email = this.$store.getters.get_user.email;
            let elements = (this.purchase_type === 'Monthly') ? this.$store.getters.get_elements_monthly : this.$store.getters.get_elements_yearly;
            this.setLoading(true);
            let stripe = this.$store.getters.get_stripe;
            const { error } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: data_api.get_checkout_url(),
                    receipt_email: this.billing_email,
                },
            });
            if (error.type === "card_error" || error.type === "validation_error") {
                this.payment_text = error.message;
            } else {
                this.payment_text = "An unexpected error occurred."
            }
            this.setLoading(false);
        }
    }
}
</script>
