import Vue from 'vue';
import App from './App';
import VueRouter from 'vue-router';
import store from './store';
import Index from './views/Index';
import Login from './views/Login';
import Signup from './views/Signup';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { fas } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
    faMapMarkerAlt,
    faBars,
    faFilter,
    faEdit,
    faWindowClose,
    faStar,
    faSpinner,
    faArrowCircleLeft,
    faArrowCircleRight,
    faChevronCircleLeft,
    faChevronCircleRight,
    faUserAlt,
    faUserCircle,
    faLock,
    faSignOutAlt,
    faCalendarAlt,
    faCalendar,
    faCalendarCheck,
    faTimesCircle,
    faComments,
    faSearch,
    faShieldAlt,
    faChevronLeft,
    faChevronRight,
    faBuilding,
    faAtom,
    faSun,
    faTrashAlt,


} from "@fortawesome/free-solid-svg-icons";

import './assets/scss/main.scss';
import Dashboard from "@/views/Dashboard.vue";
import DashboardSubscriptions from "@/components/DashboardSubscriptions.vue";
import DashboardManageAccounts from "@/components/DashboardManageAccounts.vue";
import DashboardProfile from "@/components/DashboardProfile.vue";
import DashboardHome from "@/components/DashboardHome.vue";
import DashboardCheckout from "@/components/DashboardCheckout.vue";
import DashboardIndex from "@/components/DashboardIndex.vue";

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

import UserPublicDashboard from "@/views/UserPublicDashboard.vue";

library.add(fas, faTrashAlt, faSun, faAtom, faBuilding, faChevronRight, faChevronLeft, faMapMarkerAlt, faBars, faFilter, faEdit, faWindowClose, faStar, faSpinner, faArrowCircleLeft, faArrowCircleRight, faChevronCircleLeft, faChevronCircleRight, faUserAlt, faUserCircle, faLock, faSignOutAlt, faCalendarAlt, faCalendar, faCalendarCheck, faTimesCircle, faComments, faStar, faSearch, faShieldAlt)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false


const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/', component: Index
        },
        {
            path: '/users/:user_key', component: UserPublicDashboard
        },
        {
            path: '/login', component: Login
        },
        {
            path: '/signup', component: Signup
        },
        {
            path: '/dashboard', component: Dashboard, redirect: "/home", children: [
                {
                    path: '/subscriptions',
                    component: DashboardSubscriptions
                },
                {
                    path: "/manage_accounts",
                    component: DashboardManageAccounts
                },
                {
                    path: "/profile",
                    component: DashboardProfile
                },
                {
                    path: "/home",
                    component: DashboardHome
                },
                {
                    path: "/checkout",
                    component: DashboardCheckout
                },
                {
                    path: "/aggregate_index",
                    component: DashboardIndex
                },
            ]
        }
    ]
}
)

const vue_app = new Vue({
    router,
    store,
    render: h => h(App),
})

vue_app.$mount('#app')





