<template>
    <div class="dashboard-child-view home">
        <!--        <div class="header-toggle">-->
        <!--            <label class="toggle" @click="toggle_visibility">-->
        <!--                <input class="toggle-checkbox" :checked="get_profile_visibility" type="checkbox">-->
        <!--                <div class="toggle-switch"></div>-->
        <!--                <span class="toggle-label" v-if="get_profile_visibility">public</span>-->
        <!--                <span class="toggle-label" v-else>private</span>-->
        <!--            </label>-->
        <!--        </div>-->
        <!--        <div v-else-if="get_profile_visibility" class="profile-url">-->
        <!--            <span class="profile-url">{{ get_profile_url }}</span>-->
        <!--        </div>-->
        <div>
            <div class="dashboard-title">
                Welcome to your Investment Dashboard
            </div>
            <div class="securities" :class="{ 'connected': securities.length }">
                <div class="securities-inner">
                    <div class="table-title">Securities</div>
                    <div class="table-parent">
                        <div v-if="securities.length" class="container-fluid data-table">
                            <div class="row columns">
                                <div class="col">
                                    <div class="column-title">
                                        Symbol
                                        <font-awesome-icon :icon="['fas', 'sort']" />
                                    </div>
                                </div>
                                 <div class="col">
                                    <div class="column-title">
                                        Quantity
                                        <font-awesome-icon :icon="['fas', 'sort']" />
                                    </div>
                                </div>
                               <!-- <div class="col">
                                    <div class="column-title">
                                        Average Price
                                        <font-awesome-icon :icon="['fas', 'sort']" />
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="column-title">
                                        Current Price
                                        <font-awesome-icon :icon="['fas', 'sort']" />
                                    </div>
                                </div> -->
                                <!-- <div class="col"> -->
                                    <!-- <div class="column-title">Profit/Loss $</div> -->
                                    <!--                                    <div class="row" v-for="security in securities">-->
                                    <!--                                        <div class="col-12">{{ calc_profit(security) }}</div>-->
                                    <!--                                    </div>-->
                                <!-- </div> -->
                                <!--                                <div class="col">Profit/Loss %</div>-->
                                <!--                                <div class="col">Total Value</div>-->
                            </div>
                            <div class="row table-rows">
                                <div class="col">
                                    <div class="row" v-for="security in securities">
                                        <div class="col-12">{{ security['ticker_symbol'] }}</div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="row" v-for="security in securities">
                                        <div class="col-12">{{ format_num(security['quantity']) }}</div>
                                    </div>
                                </div>
                                <!-- <div class="col">
                                    <div class="row" v-for="security in securities">
                                        <div class="col-12">{{ format_num(security['cost_basis']) }}</div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="row" v-for="security in securities">
                                        <div class="col-12">{{ format_num(security['institution_value']) }}</div>
                                    </div>
                                </div> -->
                                <!-- <div class="col">
                                    <div class="row" v-for="security in securities">
                                        <div class="col-12">{{ calc_profit(security) }}</div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div v-else class="container-fluid data-table">
                            <div class="row columns">
                                <div class="col">
                                    <div class="column-title">Symbol</div>
                                </div>
                                <div class="col">
                                    <div class="column-title">Quantity</div>
                                </div>
                                <!-- <div class="col">
                                    <div class="column-title">Average Price</div>
                                </div>
                                <div class="col">
                                    <div class="column-title">Current Price</div>
                                </div>
                                <div class="col">
                                    <div class="column-title">Profit/Loss $</div>
                                </div> -->
                                <!--                                <div class="col">-->
                                <!--                                    <div class="column-title">-->
                                <!--                                        Profit/Loss %-->
                                <!--                                    </div>-->
                                <!--                                </div>-->
                                <!--                                <div class="col">-->
                                <!--                                    <div class="column-title">-->
                                <!--                                        Total Value-->
                                <!--                                    </div>-->
                                <!--                                </div>-->
                            </div>
                            <div v-if="investments_loading" class="row no-data-loaded">
                                <div class="col-12 text-center">
                                    <font-awesome-icon class="fa-spin" :icon="['fa', 'sun']"></font-awesome-icon>
                                </div>
                            </div>
                            <div v-else class="row no-data-loaded">
                                <div class="col-12 text-center">
                                    <div class="no-data-text">Investment data will appear here</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="transactions" :class="{ 'connected': transactions.length }">
                <div class="transactions-inner">
                    <div class="table-title">Transactions</div>
                    <div class="table-parent">
                        <div v-if="transactions.length" class="container-fluid data-table">
                            <div class="row columns">
                                <div class="col">
                                    <div class="column-title">Symbol</div>
                                </div>
                                <div class="col">
                                    <div class="column-title">QUANTITY</div>
                                </div>
                                <div class="col">
                                    <div class="column-title">AMOUNT</div>
                                </div>
                                <div class="col">
                                    <div class="column-title">PRICE</div>
                                </div>
                                <div class="col">
                                    <div class="column-title">DATE</div>
                                </div>
                            </div>
                            <div class="row table-rows">
                                <div class="col">
                                    <div class="row" v-for="transaction in transactions">
                                        <div class="col-12">{{ get_symbol(transaction['security_id']) || 'N/A' }}</div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="row" v-for="transaction in transactions">
                                        <div class="col-12">{{ format_num(transaction['quantity']) || 'N/A' }}</div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="row" v-for="transaction in transactions">
                                        <div class="col-12">{{ format_num(transaction['amount']) || 'N/A' }}</div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="row" v-for="transaction in transactions">
                                        <div class="col-12">{{ format_num(transaction['price']) || 'N/A' }}</div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="row" v-for="transaction in transactions">
                                        <div class="col-12">{{ format_date(transaction['date']) || 'N/A' }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="container-fluid data-table">
                            <div class="row columns">
                                <div class="col">
                                    <div class="column-title">Symbol</div>
                                </div>
                                <div class="col">
                                    <div class="column-title">QUANTITY</div>
                                </div>
                                <div class="col">
                                    <div class="column-title">AMOUNT</div>
                                </div>
                                <div class="col">
                                    <div class="column-title">PRICE</div>
                                </div>
                                <div class="col">
                                    <div class="column-title">DATE</div>
                                </div>
                            </div>
                            <div v-if="transactions_loading" class="row no-data-loaded">
                                <div class="col-12 text-center">
                                    <font-awesome-icon class="fa-spin" :icon="['fa', 'sun']"></font-awesome-icon>
                                </div>
                            </div>
                            <div v-else class="row no-data-loaded">
                                <div class="col-12 text-center">
                                    <div class="no-data-text">Investment data will appear here</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import data_api from "@/store/api/data_api";

export default {
    name: "DashboardHome",
    data() {
        return {
            securities_columns: [],
            transactions_columns: []
        }
    },
    methods: {
        get_symbol: function (security_id) {
            let securities = this.$store.getters.get_securities;
            if (securities.length) {
                for (let i = 0; i < securities.length; i++) {
                    if (securities[i].security_id === security_id) {
                        return securities[i].ticker_symbol;
                    }
                }
            }
            return '';
        },
        load_securities: function () {
            let securities = this.$store.getters.get_securities
            if (securities.length) {
            } else {
                this.$store.dispatch('fetch_investments').then(resp => {
                    console.log("investments resp", resp);
                })
            }
        },
        load_transactions: function () {
            let transactions = this.$store.getters.get_transactions;
            if (transactions.length) {
            } else {
                this.$store.dispatch('fetch_transactions').then(resp => {
                    console.log("transactions resp", resp);
                })
            }
        },
        calc_profit: function (security) {
            let cost_basis = security.cost_basis;
            let price_sold = security.institution_value;
            let current_profit = ((price_sold - cost_basis) / cost_basis) * 100;
            return current_profit.toFixed(2)
        },
        format_num: function (number) {
            if (number) {
                return number.toFixed(2)
            }
            return 'N/A';
        },
        format_date: function (str) {
            return new Date(str).toLocaleDateString();
        },
        toggle_visibility: function () {
            data_api.create_fetch_request("GET", data_api.toggle_visibility_url).then(resp => {
                if (resp.success) {
                    this.$store.commit('toggle_visibility', resp.visibility)
                }
            })
        },
    },
    mounted() {
        this.load_securities();
        this.load_transactions();
    },
    computed: {
        investments_loading: function () {
            return this.$store.getters.get_investments_loading;
        },
        transactions_loading: function () {
            return this.$store.getters.get_transactions_loading;
        },
        get_profile_visibility: function () {
            // return false;
            return this.$store.getters.get_user.visibility
        },
        get_profile_url: function () {
            let profile_key = this.$store.getters.get_user.user_profile_key;
            return `${window.location.origin}/users/${profile_key}`
        },
        securities: function () {
            return this.$store.getters.get_securities;
        },
        transactions: function () {
            let transactions = this.$store.getters.get_transactions;
            if (transactions && transactions.length > 0) {
                this.transactions_columns = Object.keys(transactions[0])
                return transactions
            }
            return []
        }
    }
}
</script>
