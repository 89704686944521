<template>
    <div class="aggregate-index dashboard-child-view">
        <div class="dashboard-title">Collective Float</div>
        <div class="collective-float">
            <div class="table-title">We bring you our collective regards</div>
            <div class="table-parent">
                <div v-if="subscription" class="container-fluid data-table">
                    <div class="row columns">
                        <div class="col">
                            <div class="column-title">Symbol</div>
                        </div>
                        <div class="col">
                            <div class="column-title">Total Shares</div>
                        </div>
                        <!-- <div class="col">
                            <div class="column-title">Total Float</div>
                        </div>
                        <div class="col">
                            <div class="column-title">Retail Owned Float %</div>
                        </div> -->
                    </div>

                    <div v-if="subscription_loading || aggregate_loading" class="row no-data-loaded">
                        <div class="col-12 text-center">
                            <font-awesome-icon class="fa-spin" :icon="['fa', 'sun']"></font-awesome-icon>
                        </div>
                    </div>

                    <div v-else-if="!get_aggregate_data" class="row no-data-loaded">
                        <div class="col-12 text-center">
                            <span class="no-data-text">Collective float will appear here</span>
                        </div>
                    </div>

                    <div v-else class="row table-rows">
                        <div class="col">
                            <div class="row" v-for="(value, key) in get_aggregate_data">
                                <div class="col-12">{{ key }}</div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row" v-for="(value, key) in get_aggregate_data">
                                <div class="col-12">{{format_num(value) }}</div>
                            </div>
                        </div>
                        <!-- <div class="col">
                            <div class="row" v-for="(value, key) in get_aggregate_data">
                                <div class="col-12">{{ 'N/A' }}</div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row" v-for="(value, key) in get_aggregate_data">
                                <div class="col-12">{{ 'N/A' }}</div>
                            </div>
                        </div> -->
                    </div>
                </div>

                <div v-else class="container-fluid data-table">
                    <div class="row columns">
                        <div class="col">
                            <div class="column-title">Symbol</div>
                        </div>
                        <div class="col">
                            <div class="column-title">Total Shares</div>
                        </div>
                        <!-- <div class="col">
                            <div class="column-title">Total Float</div>
                        </div>
                        <div class="col">
                            <div class="column-title">Retail Owned Float %</div>
                        </div> -->
                    </div>
                    <div v-if="subscription_loading || aggregate_loading" class="row no-data-loaded">
                        <div class="col-12 text-center">
                            <font-awesome-icon class="fa-spin" :icon="['fa', 'sun']"></font-awesome-icon>
                        </div>
                    </div>
                    <div v-else class="row no-data-loaded">
                        <div class="col-12 text-center">
                            <span class="no-data-text">Collective float will appear here</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
export default {
    name: "DashboardIndex",
    mounted() {
        let aggregate_data = this.$store.getters.get_aggregate_data;
        if (Object.keys(aggregate_data).length === 0) {
            this.$store.dispatch('fetch_aggregate');
        }
    },
    data() {
        return {
            currentSort: '',
            currentSortDir: 'asc'
        }
    },
    computed: {
        get_aggregate_data: function () {
            let aggregate = this.$store.getters.get_aggregate_data;
            let keys = Object.keys(aggregate)
            if (keys.length) {
                // if float has loaded dont fetch
                // if (aggregate[keys[0]].hasOwnProperty('float')) {
                //
                // } else {
                //     // console.log("agg", aggregate);
                //     this.$store.dispatch('fetch_floats', aggregate);
                // }
                return aggregate
            }
            return null;
        },
        subscription_loading: function () {
            return this.$store.getters.get_subscriptions_loading;
        },
        aggregate_loading: function () {
            return this.$store.getters.get_aggregate_loading;
        },
        subscription: function () {
            let subscription = this.$store.getters.get_subscription;
            return (Object.keys(subscription).length) ? subscription : null
        }
    },
    methods: {
        format_num: function(number) {
            if (number) {
                return number.toFixed(2)
            }
            return 'N/A';
        },
        sort(s) {
            // if the same header is clicked twice, reverse the sorting direction
            // if(s === this.currentSort) {
            //     this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            // }
            // this.currentSort = s;
            // let sortedData = Object.entries(this.get_aggregate_data).sort((a, b) => {
            //     let modifier = 1;
            //     if(this.currentSortDir === 'desc') modifier = -1;
            //     if(a[1][this.currentSort] < b[1][this.currentSort]) return -1 * modifier;
            //     if(a[1][this.currentSort] > b[1][this.currentSort]) return 1 * modifier;
            //     return 0;
            // });
            // // Transform sorted array back to object
            // this.$store.dispatch('setAggregateData', Object.fromEntries(sortedData));
        },
    }
}
</script>