<template>
    <div class="dashboard view">
        <div class="dashboard-sidenav" :class="{'sidenav-open': sidenav_open}">
            <CanvasSidenav></CanvasSidenav>
            <div class="dashboard-sidenav-element" @click="goto('/home')" :class="{'active': current_link === 'dashboard_home'}">
                Investment Dashboard
            </div>
            <div class="dashboard-sidenav-element" @click="goto('/subscriptions')" :class="{'active': current_link === 'dashboard_subscriptions'}">
                Subscriptions
            </div>
            <div class="dashboard-sidenav-element" @click="goto('/aggregate_index')" :class="{'active': current_link === 'dashboard_index'}">
                Collective Float
            </div>
            <div class="dashboard-sidenav-element" @click="goto('/manage_accounts')" :class="{'active': current_link === 'dashboard_manage_accounts'}">Manage Accounts</div>
            <div class="dashboard-sidenav-element" @click="goto('/profile')" :class="{'active': current_link === 'dashboard_profile'}">My Profile</div>
            <div class="btn-toggle-sidenav" @click="toggle_sidenav">
                <font-awesome-icon v-if="sidenav_open" :icon="['fa', 'fa-chevron-left']"></font-awesome-icon>
                <font-awesome-icon v-else :icon="['fa', 'fa-chevron-right']"></font-awesome-icon>
            </div>
        </div>
        <div class="dashboard-main" :class="{'sidenav-open': sidenav_open}">
            <router-view @goto="goto"></router-view>
        </div>
    </div>
</template>

<script>
import CanvasSidenav from "@/views/CanvasSidenav.vue";
import data_api from "@/store/api/data_api";
export default {
    name: "Dashboard",
    components: {
        CanvasSidenav
    },
    data() {
        return {
            current_link: '',
            route_map: {
                "/home": "dashboard_home",
                "/subscriptions": "dashboard_subscriptions",
                "/manage_accounts": "dashboard_manage_accounts",
                "/profile": "dashboard_profile",
                "/aggregate_index": "dashboard_index",
            }
        }
    },
    async mounted() {
        let current_path = this.$router.currentRoute.path;
        this.current_link = this.route_map[current_path];
        let current_subscription = this.$store.getters.get_subscription;
        if (current_subscription) {
        } else {
            data_api.get_subscriptions().then(resp => {
                if (resp.success) {
                    // console.log(resp.subscription);
                    this.$store.commit('set_subscription', resp.subscription)
                }
            });
        }
    },
    computed: {
        sidenav_open: function() {
            return this.$store.getters.get_sidenav_open;
        }
    },
    methods: {
        toggle_sidenav: function() {
            let stripe_form_visible = this.$store.getters.get_stripe_form_visable;
            if (!stripe_form_visible) {
                let sidenav_open = this.$store.getters.get_sidenav_open;
                this.$store.commit('set_side_nav_open', !sidenav_open);
            }
        },
        goto: function(route) {
            this.current_link = this.route_map[route];
            this.$router.push(route)
            this.$store.commit('set_side_nav_open', false)
        }
    }
}
</script>