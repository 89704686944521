<template>
    <div class="signup auth view">
        <div class="auth-form">
            <div class="title-text">Signup</div>
            <div class="form-error" v-if="form_error">{{ form_error }}</div>
            <div class="input-container">
                <input
                    type="text"
                    id="name"
                    name="name"
                    aria-labelledby="label-name"
                    v-model="username"
                    :class="{active: username_active}"
                    @input="username_onenter"
                />
                <label class="label" for="name" id="label-name">
                    <div class="text">Username</div>
                </label>
            </div>
            <div class="input-container">
                <input
                    type="text"
                    id="email"
                    name="email"
                    aria-labelledby="label-email"
                    v-model="email"
                    :class="{active: email_active}"
                    @input="email_onenter"
                />
                <label class="label" for="email" id="label-email">
                    <div class="text">Email</div>
                </label>
            </div>
            <div class="input-container">
                <input
                    type="password"
                    id="password"
                    name="password"
                    aria-labelledby="label-password"
                    v-model="password"
                    :class="{active: password_active}"
                    @input="password_onenter"
                />
                <label class="label" for="password" id="label-password">
                    <div class="text">Password</div>
                </label>
            </div>
            <div class="input-container">
                <input
                    type="password"
                    id="confirm-password"
                    name="confirm-password"
                    aria-labelledby="label-confirm-password"
                    v-model="confirm_password"
                    :class="{active: confirm_password_active}"
                    @input="confirm_password_onenter"
                />
                <label class="label" for="confirm-password" id="label-confirm-password">
                    <div class="text">Confirm password</div>
                </label>
            </div>
            <div>
                <input type="checkbox" id="tos" name="tos" class="checkbox" v-model="checked" />
                <label for="horns">By checking this box I agree that I understand that Retail Float is not a financial advisor and does not offer investment advice and I will not hold Retail float liable any investment decisions I decide to make.</label>
            </div>
            <button v-if="auth_in_action" :disabled="auth_in_action" class="login-btn action-btn">
                <font-awesome-icon class="fa-spin" :icon="['fa', 'spinner']"></font-awesome-icon>
            </button>
            <button v-else @click="signup" class="login-btn action-btn">Signup</button>
            <div class="text-redirect" @click="$router.push('/login')">Already have an account? Go to login</div>
        </div>
        <div style="display: none">{{ get_user }}</div>
    </div>
</template>
<script>
export default {
    name: "Signup",
    mounted() {
        let self = this;
        document.body.onkeyup = function(e) {
            if (e.key === 'Enter') {
                self.signup();
            }
        }
    },
    data() {
        return {
            email: '',
            password: '',
            username: '',
            confirm_password: '',
            email_active: false,
            username_active: false,
            password_active: false,
            confirm_password_active: false,
            form_error: '',
            auth_in_action: false,
            checked: false
        }
    },
    computed: {
        get_user: function() {
            let user = this.$store.getters.get_user;
            if (user.email) {
                this.$router.push('/dashboard')
            }
        }
    },
    methods: {
        email_onenter: function(e) {
            if (e.target.value.trim().length > 0) {
                this.email_active = true;
            } else {
                this.email_active = false
            }
        },
        password_onenter: function(e) {
            if (e.target.value.trim().length > 0) {
                this.password_active = true;
            } else {
                this.password_active = false
            }
        },
        username_onenter: function(e) {
            if (e.target.value.trim().length > 0) {
                this.username_active = true;
            } else {
                this.username_active = false
            }
        },
        confirm_password_onenter: function(e) {
            if (e.target.value.trim().length > 0) {
                this.confirm_password_active = true;
            } else {
                this.confirm_password_active = false;
            }
        },
        signup: function() {
            let self = this;
            let email = this.email.trim()
            let name = this.username.trim();
            if (!this.checked) {
                this.form_error = "Please accept TOS"
            } else {
                if (email && name) {
                    let password = this.password.trim();
                    let confirm_password = this.confirm_password.trim()
                    if (password && password === confirm_password) {
                        this.auth_in_action = true;
                        let payload = {email: email, password: password, username: name}
                        this.$store.dispatch('signup', payload).then(resp => {
                            self.auth_in_action = false;
                            if (resp && resp.success) {
                                this.$store.commit('set_user', resp.session)
                                this.$router.push('/dashboard');
                            } else if (resp.error) {
                                this.form_error = resp.error;
                            }
                        })
                    } else if (password) {
                        this.form_error = "Passwords do not match"
                    } else {
                        this.form_error = "Please provide a password";
                    }
                } else {
                    this.form_error = "Please enter a valid email"
                }
            }
        }
    }
}
</script>