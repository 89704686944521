<template>
    <div class="canvas-sidenav-container">
        <canvas></canvas>
    </div>
</template>

<script>
export default {
    name: "CanvasSidenav",
    mounted() {
        this.load_background();
    },
    methods: {
        load_background: function () {
        }
    }
}
</script>
