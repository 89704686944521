<template>
    <b-navbar toggleable="md" class="header">
        <b-navbar-brand @click="$router.push('/')" class="header-brand">
            <img class="logo" :src="header_logo" alt="">
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav v-if="user_exists">
                <!--            <b-nav-item>Signup</b-nav-item>-->
            </b-navbar-nav>
            <b-navbar-nav v-if="user_exists" class="ml-auto auth">
                <b-nav-item>
                    <b-dropdown size="sm" variant="link" toggle-class="">
                        <template #button-content>
                            <span class="header-email-name">{{ username }}</span>
                            <font-awesome-icon :icon="['fa', 'user-alt']"></font-awesome-icon>
                        </template>
<!--                        <b-dropdown-item href="#" @click="goto_profile">-->
<!--                            <font-awesome-icon :icon="['fa', 'user-circle']"></font-awesome-icon>-->
<!--                            Profile-->
<!--                        </b-dropdown-item>-->
<!--                        <b-dropdown-item href="#" @click="goto_dashboard">-->
<!--                            <font-awesome-icon :icon="['fa', 'user-circle']"></font-awesome-icon>-->
<!--                            Dashboard-->
<!--                        </b-dropdown-item>-->
                        <b-dropdown-item href="#" @click="logout">
                            <font-awesome-icon :icon="['fa', 'sign-out-alt']"></font-awesome-icon>
                            Logout
                        </b-dropdown-item>
                        <b-dropdown-item href="#" @click="$router.push('/dashboard')">
                            <font-awesome-icon :icon="['fa', 'sign-out-alt']"></font-awesome-icon>
                            Dashboard
                        </b-dropdown-item>
<!--                        <b-dropdown-item href="#" v-if="is_admin">-->
<!--                            <font-awesome-icon :icon="['fa', 'lock']"></font-awesome-icon>-->
<!--                            Admin-->
<!--                        </b-dropdown-item>-->
                    </b-dropdown>
                </b-nav-item>
            </b-navbar-nav>

            <b-navbar-nav v-else class="ml-auto no-auth">
                <b-nav-item class="auth-nav-item"  @click="goto_login">Login</b-nav-item>
                <b-nav-item class="auth-nav-item" @click="goto_signup">Signup</b-nav-item>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>

import site_logo from '@/assets/img/retail-float-logo.png';
import data_api from "@/store/api/data_api";

export default {
    name: "Header",
    data() {
        return {
            header_logo: site_logo
        }
    },
    computed: {

        user_exists: function() {
            return this.$store.getters.get_user.hasOwnProperty('email');
        },
        is_admin: function() {
            return this.$store.getters.get_user.is_admin
        },
        username: function() {
            let user = this.$store.getters.get_user;
            if (user.username) {
                return `welcome, ${user.username}`;
            }
            return '';
        },

    },
    methods: {

        goto_login: function() {
            this.$store.commit('set_current_result', null);
            this.$router.push('/login')
            // this.$store.commit('set_side_nav', true)
        },
        goto_signup: function() {
            this.$store.commit('set_current_result', null);
            this.$router.push('/signup');
        },
        logout: function() {
            let self = this;
            data_api.create_fetch_request("GET", data_api.logout_url).then(resp => {
                if (resp.success) {
                    // console.log("logging out")
                    // self.$store.commit('set_user', {})
                    // self.$store.commit('set_subscription', undefined)
                    // self.$store.commit('set_transactions', [])
                    // self.$store.commit('set_securities', [])
                    self.$store.dispatch('reset_store').then(() => {
                        console.log("user", self.$store.getters.get_user)
                    });
                    self.$router.push('/login');
                }
            })
        },
    }
}
</script>
