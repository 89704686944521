<template>
    <div class="view public-profile">
        <div class="title-text" v-if="username">{{ username }}'s Holdings</div>
        <div class="form-error">{{ error }}</div>
        <div v-if="holdings.length > 0" class="holdings">
            <div class="table-title">Holdings</div>
            <table class="data-table">
                <thead>
                    <tr>
                        <td>Name</td>
                        <td>Quantity</td>
                        <td>Symbol</td>
                        <td>Profit/Loss</td>
                        <td>Type</td>
                        <td>Cost Price</td>
                        <td>Value</td>
                    </tr>
                </thead>
                <tbody>
                <tr v-for="security in holdings">
                    <td>
                        <div class="name">{{ security['name']}}</div>
                    </td>
                    <td>
                        <div>{{ security['quantity'] }}</div>
                    </td>
                    <td>
                        <div>{{ security['ticker_symbol'] }}</div>
                    </td>
                    <td>
                        <div>{{ calc_profit(security) }}</div>
                    </td>
                    <td>
                        {{ security['type'] }}
                    </td>
                    <td>
                        {{ security['cost_basis'] }}
                    </td>
                    <td>
                        {{ security['institution_value'] }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div v-else>
            <span v-if="username">
                {{ username }} does not have any holdings at this time. Please check again later.
            </span>
        </div>
    </div>
</template>

<script>

import data_api from "@/store/api/data_api";

export default {
    name: "UserPublicDashboard",
    mounted() {
        let user_key = this.$route.params.user_key;
        if (user_key) {
            let request_body = {"user_key": user_key}
            data_api.create_fetch_request("POST", data_api.get_public_profile_url, request_body).then(resp => {
                if (resp.success) {
                    this.username = resp.username;
                    this.holdings = resp.holdings;

                } else if (resp.error) {
                    this.error = resp.error;
                }
            });
        } else {
            this.$router.push('/')
        }
    },
    data() {
        return {
            username: '',
            holdings: [],
            error: ''
        }
    },
    methods: {
        calc_profit: function(security) {
            let cost_basis = security.cost_basis;
            let price_sold = security.institution_value;
            let current_profit = ((price_sold - cost_basis) / cost_basis) * 100;
            return current_profit
        }
    }
}
</script>
